import React from "react";
import Contactus from "../contactus/Contactus";
import LeftParallaxContainer from "./components/LeftParallaxContainer";
import RightParallaxContainer from "./components/RightParallaxContainer";

export default function Reinterpretation(props) {
    const {SetClientInterpretation} = props;

    function reinterpretationClickHandler() {
        SetClientInterpretation(null);
        const targetSection = document.getElementById("interpretation-section");
        if (targetSection) {
            targetSection.scrollIntoView({behavior: 'smooth'});
        }
    }

    return (
        <section className="py-20 pb-14 max-xl:py-10 max-xl:pb-14 max-md:py-6">
            <div className="inside">
                <div className="mb-4 text-[#b8b5b5] text-base leading-normal">Повторна інтерпретація</div>
                <div
                    className="mb-12 text-[#00120a] text-[40px] font-bold leading-[130%] max-xl:text-[32px] max-md:text-2xl max-md:mb-8">Бажаєте
                    перевірити ще інші аналізи?
                </div>
                <div
                    className="mb-12 w-[877px] max-w-full mx-auto text-center text-[#00120a] text-lg leading-[150%] max-xl:text-base max-md:mb-8">Завдяки
                    цьому інструменту користувачі отримують чітке, легке для розуміння тлумачення аналізів, що
                    дозволяє їм
                    ухвалювати більш обґрунтовані та правильні рішення щодо свого здоров'я.
                </div>
                <div className="button pulsate" onClick={reinterpretationClickHandler}>
                    Повторити інтерпретацію
                </div>
                <div
                    className="relative mx-auto my-14 h-[449px] w-full rounded-2xl max-xl:mx-[-48px] max-xl:w-[calc(100%+96px)] max-xl:mb-10 max-md:mt-8 max-md:h-[208px] max-md:mx-[-16px] max-md:w-[calc(100%+32px)]">
                    <img className="w-full h-full object-cover rounded-2xl" src="/img/start-bg.png"/>

                    <LeftParallaxContainer/>

                    <RightParallaxContainer/>

                </div>
                <Contactus/>
            </div>
        </section>
    )
}