import React, { createContext, useContext } from 'react';
import { useDisclosure } from '@mantine/hooks';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const [opened, { open, close }] = useDisclosure(false);

    return (
        <ModalContext.Provider value={{ opened, open, close }}>
            {children}
        </ModalContext.Provider>
    );
};

export const useModal = () => useContext(ModalContext);