import React, { useEffect, useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import PdfReport from './components/PdfReport';
import Loader from "../../components/loader/Loader";

export default function PdfResultPage() {
    const [pdfUrl, setPdfUrl] = useState(null);
    const [pdfData, setPdfData] = useState(null); // Добавляем состояние для данных

    useEffect(() => {
        const storedData = localStorage.getItem("clientInterpretation");
        if (storedData) {
            setPdfData(JSON.parse(storedData));
        }
    }, []);

    useEffect(() => {
        if (pdfData) {
            const generatePDF = async () => {
                try {
                    const blob = await pdf(<PdfReport data={pdfData} />).toBlob();
                    const url = URL.createObjectURL(blob);
                    setPdfUrl(url);
                    const pdfWindow = window.open(url);
                    pdfWindow.focus();
                } catch (error) {
                    console.error("Error generating PDF:", error);
                }
            };

            generatePDF();
        }
    }, [pdfData]);

    useEffect(() => {
        if (pdfUrl) {
            setTimeout(() => {
                window.close();
            }, 3000);
        }
    }, [pdfUrl]);

    if (!pdfData) return <div><Loader isLoading={true} /></div>; // Показываем загрузчик пока нет данных

    return <section></section>;
}


// import React, {useEffect, useState} from 'react';
// import {pdf} from '@react-pdf/renderer';
// import PdfReport from './components/PdfReport';
// import Loader from "../../components/loader/Loader";
//
// export default function PdfResultPage() {
//     const [pdfUrl, setPdfUrl] = useState(null);
//
//     useEffect(() => {
//         const generatePDF = async () => {
//             try {
//                 const blob = await pdf(<PdfReport/>).toBlob();
//                 const url = URL.createObjectURL(blob);
//                 setPdfUrl(url);
//                 const pdfWindow = window.open(url);
//                 pdfWindow.focus();
//
//             } catch (error) {
//                 console.error("Error generating PDF:", error);
//             }
//         };
//
//         generatePDF();
//     }, []);
//
//     useEffect(() => {
//         if (pdfUrl) {
//             setTimeout(() => {
//                 window.close();
//             }, 100)
//         }
//     }, [pdfUrl]);
//
//     if (!pdfUrl) return <div><Loader isLoading={true}/></div>;
//
//     return (
//         <section></section>
//     );
// }

// // import {pdf, PDFViewer} from '@react-pdf/renderer';
// import PdfReport from "./components/PdfReport";
// import PDFViewer from 'pdf-viewer-reactjs'
// export default function PdfResultPage() {
//     return (
//         <>
//             <section>
//                 <div className="inside">
//                     {/*<div className="py-16 max-xl:pt-20 max-xl:pb-14 max-md:pt-14 max-md:pb-12">*/}
//                     {/*    <PDFViewer style={{width: '100%', height: '100vh', overflow:'auto'}}>*/}
//                     {/*        <PdfReport />*/}
//                     {/*    </PDFViewer>*/}
//                     {/*</div>*/}
//
//                     <PDFViewer
//                         document={<PdfReport />}
//                     />
//                 </div>
//             </section>
//         </>
//     );
// }


// export default function PdfResultPage() {
//     const downloadPDF = async () => {
//         const blob = await pdf(<PdfReport />).toBlob();
//         const url = URL.createObjectURL(blob);
//         const pdfWindow = window.open(url, "_blank");
//         pdfWindow.focus();
//     };
//
//     return (
//         <>
//             <section>
//                 <div className="inside">
//                     <div className="py-16 max-xl:pt-20 max-xl:pb-14 max-md:pt-14 max-md:pb-12">
//                         <button onClick={downloadPDF} className="button pulsate">
//                             Открыть PDF в новой вкладке
//                         </button>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// }