import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export default function AnchorLink({ href, children }) {
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = (e) => {
        e.preventDefault();
        const targetPath = href.split('#')[0];
        const targetId = href.split('#')[1];

        if (location.pathname === targetPath) {
            // Same page, smooth scroll to the section
            const targetSection = document.getElementById(targetId);
            if (targetSection) {
                targetSection.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            // Navigate to the target path, including the hash
            navigate(href);
        }
    };

    return (
        <div onClick={handleClick}>
            {children}
        </div>
    );
}