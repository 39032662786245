import Contactus from "../MainPage/components/contactus/Contactus";
import {Link} from "react-router-dom";

export default function NotFoundPage() {
    return (
        <>
            <section>
                <div className="inside">
                    <div className="py-32 max-xl:pt-20 max-xl:pb-14 max-md:pt-14 max-md:pb-12">
                        <div>
                            <img
                                className="m-auto max-w-full max-h-full object-cover"
                                src="/img/icon404.svg"
                                alt="Сторiнку не знайдено"
                            />
                        </div>
                        <div className="mb-10 text-[#00120a] text-2xl font-bold leading-[140%]">
                            Упс, щось трапилось....
                        </div>
                        <div className="mb-10 text-center text-[#00120a] text-lg font-normal leading-[140%]">
                            Перезавантажте сторінку або спробуйте пізніше
                        </div>
                        <Link to="/" className="button">Перейти на головну</Link>
                    </div>
                    <div className="pb-14">
                        <Contactus/>
                    </div>
                </div>
            </section>
        </>
    );
}
