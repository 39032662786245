export default function IconFile(props) {
    const {size} = props;
    return (
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.3">
                <path
                    d="M20.8343 4.98131L16.3145 0.705281C15.8337 0.250453 15.2045 0 14.5427 0H5.9375C4.51592 0 3.35938 1.15655 3.35938 2.57812V21.4219C3.35938 22.8435 4.51592 24 5.9375 24H19.0625C20.4841 24 21.6406 22.8435 21.6406 21.4219V6.85416C21.6406 6.14873 21.3467 5.46609 20.8343 4.98131ZM19.4685 5.625H15.9688C15.8395 5.625 15.7344 5.51986 15.7344 5.39062V2.09231L19.4685 5.625ZM19.0625 22.5938H5.9375C5.29133 22.5938 4.76562 22.068 4.76562 21.4219V2.57812C4.76562 1.93195 5.29133 1.40625 5.9375 1.40625H14.3281V5.39062C14.3281 6.29527 15.0641 7.03125 15.9688 7.03125H20.2344V21.4219C20.2344 22.068 19.7087 22.5938 19.0625 22.5938Z"
                    fill="white"/>
                <path
                    d="M17.5156 9.375H7.20312C6.81481 9.375 6.5 9.68981 6.5 10.0781C6.5 10.4664 6.81481 10.7812 7.20312 10.7812H17.5156C17.9039 10.7812 18.2188 10.4664 18.2188 10.0781C18.2188 9.68981 17.9039 9.375 17.5156 9.375Z"
                    fill="white"/>
                <path
                    d="M17.5156 13.125H7.20312C6.81481 13.125 6.5 13.4398 6.5 13.8281C6.5 14.2164 6.81481 14.5312 7.20312 14.5312H17.5156C17.9039 14.5312 18.2188 14.2164 18.2188 13.8281C18.2188 13.4398 17.9039 13.125 17.5156 13.125Z"
                    fill="white"/>
                <path
                    d="M10.6119 16.875H7.20312C6.81481 16.875 6.5 17.1898 6.5 17.5781C6.5 17.9664 6.81481 18.2812 7.20312 18.2812H10.6119C11.0002 18.2812 11.315 17.9664 11.315 17.5781C11.315 17.1898 11.0002 16.875 10.6119 16.875Z"
                    fill="white"/>
            </g>
        </svg>
    )
}