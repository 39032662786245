import {Parallax} from "react-scroll-parallax";
import React, {useEffect, useState} from "react";

export default function LeftParallaxContainer() {
    const [translateYValue, setTranslateYValue] = useState(['-500px', '0px']);

    useEffect(() => {
        const updateTranslateValue = () => {
            const width = window.innerWidth;
            if (width <= 1024) {
                setTranslateYValue(['-320px', '-100px']); // планшеты
            } else {
                setTranslateYValue(['-350px', '-50px']); // десктопы
            }
        };

        updateTranslateValue();
        window.addEventListener('resize', updateTranslateValue);

        return () => window.removeEventListener('resize', updateTranslateValue);
    }, []);
    return (
        <Parallax translateY={translateYValue}>
            <div
                className="border-design border-design--type4 !absolute -top-72 left-40 z-10 w-[338px] p-8 bg-white/80 rounded-lg flex flex-col justify-center items-start gap-8 text-left max-xl:-top-6 max-[1500px]:left-12 max-[920px]:hidden">
                <div>
                    <svg width="64" height="65" viewBox="0 0 64 65" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.7">
                            <path
                                d="M51.5314 5.31431H43.8977C43.4175 5.31431 43.028 5.70797 43.028 6.19318C43.028 6.67862 43.4175 7.07205 43.8977 7.07205H47.7359V55.6033H15.2648V7.07205H18.8848V9.06852C18.8848 10.599 20.1168 11.8439 21.6314 11.8439H25.7435C26.1547 14.1373 28.1456 15.8816 30.5312 15.8816H32.4691C34.8544 15.8816 36.8453 14.1373 37.2565 11.8439H41.3686C42.8835 11.8439 44.1159 10.5988 44.1159 9.06852V5.27558C44.1159 3.74507 42.8835 2.5 41.3688 2.5H21.6314C20.1168 2.5 18.8848 3.74507 18.8848 5.27558V5.31431H11.4688C9.556 5.31431 8 6.88707 8 8.81922V48.5181C8 49.0038 8.38935 49.397 8.86976 49.397C9.34971 49.397 9.73953 49.0038 9.73953 48.5181V8.81922C9.73953 7.85608 10.5159 7.07205 11.4688 7.07205H13.5248V56.4815C13.5248 56.9672 13.9146 57.3603 14.3945 57.3603H48.6057C49.0863 57.3603 49.4755 56.9672 49.4755 56.4815V7.07205H51.5314C52.485 7.07205 53.2605 7.85608 53.2605 8.81922V58.9944C53.2605 59.9582 52.485 60.7423 51.5314 60.7423H11.4688C10.5159 60.7423 9.73953 59.9582 9.73953 58.9944V48.244C9.73953 47.759 9.34971 47.3651 8.86976 47.3651C8.38935 47.3651 8 47.759 8 48.244V58.9944C8 60.9275 9.556 62.5 11.4688 62.5H51.5314C53.4442 62.5 55 60.9275 55 58.9944V8.81922C55 6.88707 53.4442 5.31431 51.5314 5.31431ZM20.6244 5.27558C20.6244 4.71431 21.076 4.25797 21.6314 4.25797H41.3688C41.9243 4.25797 42.3759 4.71431 42.3759 5.27558V9.06852C42.3759 9.62978 41.9243 10.0861 41.3688 10.0861H36.4653C35.9846 10.0861 35.5955 10.4798 35.5955 10.965C35.5955 12.707 34.1928 14.1239 32.4691 14.1239H30.5312C28.8074 14.1239 27.405 12.707 27.405 10.965C27.405 10.4798 27.0156 10.0861 26.5352 10.0861H21.6314C21.076 10.0861 20.6244 9.62978 20.6244 9.06852V5.27558Z"
                                fill="#C3522F"/>
                            <path
                                d="M42.1268 26.0989H37.5082L34.556 18.0689C34.263 17.2706 33.1007 17.3248 32.8896 18.154L29.7188 30.5906L26.6578 21.7195C26.413 21.0104 25.4388 20.9224 25.0699 21.5768L22.5233 26.0994H19.8735C19.3905 26.0994 19 26.4887 19 26.9695C19 27.4499 19.3905 27.8396 19.8735 27.8396H23.0349C23.351 27.8396 23.6422 27.6695 23.7971 27.395L25.6394 24.1221L29.0185 33.9142C29.2998 34.7297 30.4785 34.6816 30.6912 33.8456L33.8876 21.3102L36.0776 27.2684C36.2033 27.6112 36.5309 27.8392 36.8979 27.8392H42.1265C42.6092 27.8392 43 27.4499 43 26.969C43 26.4887 42.6092 26.0989 42.1268 26.0989Z"
                                fill="#C3522F"/>
                            <path
                                d="M42.1265 40.5H19.8735C19.391 40.5 19 40.9474 19 41.5C19 42.0524 19.391 42.5 19.8735 42.5H42.1265C42.609 42.5 43 42.0524 43 41.5C43 40.9474 42.609 40.5 42.1265 40.5Z"
                                fill="#C3522F"/>
                            <path
                                d="M42.1265 48.5H19.8735C19.391 48.5 19 48.9479 19 49.5C19 50.0524 19.391 50.5 19.8735 50.5H42.1265C42.609 50.5 43 50.0524 43 49.5C43 48.9479 42.609 48.5 42.1265 48.5Z"
                                fill="#C3522F"/>
                        </g>
                    </svg>
                </div>
                <div className="text-[#06011e] text-lg font-bold leading-[130%] max-xl:text-base">
                    Ваші результати досліджень – ваші наступні кроки на шляху до здоров’я.
                </div>
            </div>
        </Parallax>
    )
}