import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {InputMask} from '@react-input/mask';
import ReCAPTCHA from "react-google-recaptcha";
import {toast} from "react-toastify";
import * as emailjs from "emailjs-com";

const MakeAnAppointmentForm = ({healer, SetAppointmentFormed}) => {
    const {
        register,
        setValue,
        setError,
        clearErrors,
        handleSubmit,
        reset,
        formState,
        formState: {errors, isSubmitting},
    } = useForm();
    const [phoneDetail, setPhoneDetail] = useState(null);
    const [phone, setPhone] = useState("");
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const onSubmit = handleSubmit(async ({fio, phone, comment}) => {
        if (!recaptchaToken) {
            toast.error("Пожалуйста, подтвердите, что вы не робот.");
            return;
        }
        if (!phoneDetail || !phoneDetail.isValid) {
            setError("phone", {
                type: "custom",
                message: "Поле обов'язкове для заповнення",
            });
            return;
        } else {
            clearErrors("phone");
        }
        console.log('Submitting form...');
        try {
            const result = await emailjs.send(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_CONSULTHEALER_TEMPLATE_ID,
                { fio, phone, comment },
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY
            );
            toast.success("Ваше повідомлення успішно надіслано");
            console.log("Email sent successfully:", result.text);
        } catch (error) {
            console.error("Error sending email:", error.text);
            toast.error("Ошибка при отправке сообщения.");
        }
        SetAppointmentFormed(true);
        console.log('Submission complete');
    });

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    const handlePhoneMask = (event) => {
        setPhoneDetail(event.detail);

        setPhone(event.detail.input);
        setValue("phone", event.detail.input);

        if (!event.detail.isValid) {
            setError("phone", {
                type: "custom",
                message: "Номер телефона не коректний",
            });
        } else {
            clearErrors("phone");
        }

        clearErrors("authStatus");
    };
    return (
        <div className="popup max-w-[1048px] px-14 py-20 max-xl:p-10 max-md:py-10 max-md:px-4">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div
                    className="mb-6 text-[#131313] text-5xl font-bold leading-[120%] max-xl:text-[32px] max-md:text-2xl max-md:text-center">
                    Заповніть форму
                </div>
                <div
                    className="mb-8 max-w-[549px] text-[#131313] text-lg leading-[150%] max-xl:text-base max-md:text-center max-md:max-w-full">
                    Введіть свої дані та наші менеджери найближчим часом зателефонують для
                    уточнення деталей
                </div>
                <div className="flex flex-col items-start gap-8">
                    <div className="w-full flex justify-start gap-8 max-md:flex-wrap">
                        <div className="w-full flex flex-col gap-2">
                            <div className="text-[#00120a] text-lg font-bold leading-[150%]">
                                ПІБ*
                            </div>
                            {errors?.fio?.type === "required" && (
                                <p className="form-review-error text-left"> Вкажіть, будь ласка, своє прізвище </p>
                            )}
                            {errors?.fio?.type === "maxLength" && (
                                <p className="form-review-error text-left">
                                    Занадто багато символів
                                </p>
                            )}
                            {errors?.fio?.type === "pattern" && (
                                <p className="form-review-error text-left">Не валiднi данi</p>
                            )}
                            <input
                                type="text"
                                {...register("fio", {
                                    required: true,
                                    maxLength: 32,
                                    pattern: /^[A-Za-zА-Яа-яЁёІіЇїЄєҐґ'’ʼ\s-]+$/
                                })}
                                placeholder="Введіть своє прізвище"
                                className="h-14 px-4 py-3 rounded border border-[#ccc] text-[#00120a] text-base font-normal leading-normal text-left bg-transparent placeholder:text-[#00120a]"
                            />
                        </div>
                        <div className="w-full flex flex-col gap-2">
                            <div className="text-[#00120a] text-lg font-bold leading-[150%]">
                                Номер телефону*
                            </div>
                            {errors?.phone?.message && (
                                <p className="form-review-error">{errors.phone.message}</p>
                            )}
                            <InputMask
                                mask="+3 8(___) ___-__-__"
                                replacement="_"
                                placeholder="+3 8(___) ___-__-__"
                                onMask={handlePhoneMask}
                                className="h-14 px-4 py-3 rounded border border-[#ccc] text-[#00120a] text-base font-normal leading-normal text-left bg-transparent placeholder:text-[#00120a]"
                            />
                        </div>
                    </div>
                    <div className="w-full flex flex-col gap-4">
                        <div className="w-full flex flex-col gap-2">
                            <div className="text-[#00120a] text-lg font-bold leading-[150%]">
                                Коментар
                            </div>

                            {errors?.comment?.type === "maxLength" && (
                                <p className="form-review-error">
                                    Занадто багато символів. Обмеження в 1000 символiв
                                </p>
                            )}
                            {errors?.comment?.type === "pattern" && (
                                <p className="form-review-error text-left">Не валiднi данi. </p>
                            )}

                            <textarea
                                type="text"
                                {...register("comment", {
                                    maxLength: 1000,
                                    pattern: /^[a-zA-Zа-яА-Я\dёЁіІїЇєЄ' ,.!@?-]+$/u,
                                })}
                                placeholder="Введіть свій коментар (до 1000 символів)"
                                className="h-32 px-4 py-3 rounded border border-[#ccc] text-[#00120a] text-base font-normal leading-normal text-left bg-transparent resize-y placeholder:text-[#00120a]"
                            />
                        </div>
                        <div className="text-sm leading-[150%]">
                            <span className="text-[#c3522f]">*</span>- поле є обов’язковим для
                            заповнення
                        </div>
                    </div>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                        onChange={handleRecaptchaChange}
                    />
                    <div className="flex items-center gap-2">
                        <button className="button" onClick={onSubmit} disabled={isSubmitting}>
                            Записатись до лікаря
                        </button>
                        {isSubmitting && <img src='/img/spinner-black.svg' alt="Logo" className="h-6 w-6"/>}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default MakeAnAppointmentForm;