export default function IconRemoveFile(props) {
    const { size, onClick } = props; // accept onClick as a prop
    return (
        <svg
            style={{ cursor: 'pointer', marginLeft: 65 }}
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick} // add onClick to the svg
        >
            <path
                d="M17 17.5L1 1.5M17 1.5L1 17.5"
                stroke="#C3522F"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
}