import {configApi} from "./config";

export const getResultsById = async (clientData) => {
    const { baseIIUrl } = configApi;
    let url = `${baseIIUrl}/results-by-id`;
    try {
        const response = await fetch(url, {
            method: "POST",
            body: clientData,
            headers: {
                //'Content-Type':'application/json',
                'Accept':'application/json'
            },
        });

        const data = await response.json();

        if (!data.success) {
            console.log("NOT SUCCESS");
        }
        return data;
    } catch (error) {
        return error;
    }
};
