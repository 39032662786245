import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {getResultsById} from "../../../../../../../api/getResultsById";
import Loader from "../../../../../../../components/loader/Loader";

export default function ClientTabForm(props) {
    const {clientInterpretation, SetClientInterpretation} = props;
    const {
        register,
        setValue,
        setError,
        clearErrors,
        handleSubmit,
        reset,
        formState,
        formState: {errors, isSubmitting},
    } = useForm();
    const [isUploadData, SetUploadData] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const [interpretationByReference, SetInterpretationByReference] = useState(null);

    useEffect(() => {
        // http://localhost:3000/?code=aaa111&order=3018062&surname=ЮЩЕНКО

        const searchParams = new URLSearchParams(location.search);
        if (searchParams.size == 3) {
            const code = searchParams.get('code');
            const order = searchParams.get('order');
            const surname = searchParams.get('surname');

            const targetSection = document.getElementById('interpretation-section');
            targetSection.scrollIntoView({ behavior: "smooth" });
            SetInterpretationByReference (
                {
                    code,
                    order,
                    surname
                }
            )
        }
    }, [location]);

    const onAutomaticSubmit = async () => {

        console.log("Submitting form...");
        const formData = new FormData();
        formData.append("dniprolab_order_id", interpretationByReference.order);
        formData.append("code", interpretationByReference.code);
        formData.append("last_name", interpretationByReference.surname);

        SetUploadData(true);
        let clientResult = await getResultsById(formData);
        SetUploadData(false);
        navigate(location.pathname, { replace: true });
        if (clientResult?.details) {
            toast.error(clientResult.details);
            return;
        }
        if (clientResult?.errors) {
            toast.error(clientResult.errors);
            return;
        } else if (!clientResult.errors) {
            SetClientInterpretation(clientResult);
        }
        console.log("Submission complete");
    };



    useEffect(() => {
        if (clientInterpretation === null) {
            reset();
        }
    }, [clientInterpretation, reset]);

    useEffect(() => {
        if (interpretationByReference) {
            // setValue("order", interpretationByReference.order);
            // setValue("code", interpretationByReference.code);
            // setValue("surname", interpretationByReference.surname);
            // setValue("terms", true);
            // setValue("anamnesis", "", { shouldValidate: false });
            // clearErrors("anamnesis");
            onAutomaticSubmit();
        }
    }, [interpretationByReference]);


    const onSubmit = handleSubmit(async (data) => {
        console.log(data);
        console.log('Submitting form...');

        const formData = new FormData();
        formData.append("dniprolab_order_id", data.order);
        formData.append("code", data.code);
        formData.append("last_name", data.surname);
        formData.append("anamnesis", data.anamnesis);

        SetUploadData(true);
        let clientResult = await getResultsById(formData);
        SetUploadData(false);
        console.log(clientResult);
        if (clientResult?.details) {
            toast.error(clientResult?.details);
            return;
        }
        if (clientResult?.errors) {
            toast.error(clientResult?.errors);
            return;
        } else if (!clientResult.errors) {
            SetClientInterpretation(clientResult);
        }
        console.log('Submission complete');
    });

    return (
        <>
            <Loader isLoading={isUploadData}/>
            <form onSubmit={handleSubmit(onSubmit)} style={{display: 'grid', gap: '1rem'}}>
                <div className="w-full flex flex-col gap-2">
                    <div className="text-white text-base font-semibold leading-normal text-left">Прізвище
                    </div>
                    {errors?.surname?.type === "required" && (
                        <p className="form-review-error text-left"> Вкажіть, будь ласка, своє прізвище </p>
                    )}
                    {errors?.surname?.type === "maxLength" && (
                        <p className="form-review-error text-left">
                            Занадто багато символів
                        </p>
                    )}
                    {errors?.surname?.type === "pattern" && (
                        <p className="form-review-error text-left">Не валiднi данi</p>
                    )}
                    <input
                        type="text"
                        {...register("surname", {
                            required: true,
                            maxLength: 32,
                            pattern: /^[A-Za-zА-Яа-яЁёІіЇїЄєҐґ'’ʼ\s-]+$/
                        })}
                        placeholder="Введіть своє прізвище"
                        className="h-12 px-4 py-3 rounded border border-[#b8b5b5] text-white text-base font-normal leading-normal text-left bg-transparent placeholder:text-[#595353]"/>
                </div>
                <div className="w-full flex flex-col gap-2">
                    <div className="text-white text-base font-semibold leading-normal text-left">Номер
                        замовлення
                    </div>
                    {errors?.order?.type === "required" && (
                        <p className="form-review-error text-left"> Заповніть, номер замовлення </p>
                    )}
                    {errors?.order?.type === "maxLength" && (
                        <p className="form-review-error text-left">
                            Занадто багато символів
                        </p>
                    )}
                    {errors?.order?.type === "pattern" && (
                        <p className="form-review-error text-left">Не валiднi данi</p>
                    )}
                    <input
                        type="text"
                        {...register("order", {
                            required: true,
                            maxLength: 9,
                            pattern: /^[0-9]{3,9}$/
                        })}
                        placeholder="0000000"
                        className="h-12 px-4 py-3 rounded border border-[#b8b5b5] text-white text-base font-normal leading-normal text-left bg-transparent placeholder:text-[#595353]"/>
                </div>
                <div className="w-full flex flex-col gap-2">
                    <div className="text-white text-base font-semibold leading-normal text-left">Код
                        пацієнта
                    </div>
                    {errors?.code?.type === "required" && (
                        <p className="form-review-error text-left"> Вкажiть код пацієнта </p>
                    )}
                    {errors?.code?.type === "pattern" && (
                        <p className="form-review-error text-left">Не валiднi данi</p>
                    )}
                    <input type="text"
                           {...register("code", {
                               required: true,
                               maxLength: 6,
                               pattern: /^[a-zA-Z0-9]{3,6}$/
                           })}
                           placeholder="000000"
                           className="h-12 px-4 py-3 rounded border border-[#b8b5b5] text-white text-base font-normal leading-normal text-left bg-transparent placeholder:text-[#595353]"/>
                </div>
                <div className="w-full flex flex-col gap-2">
                    <div className="text-white text-base font-semibold leading-normal text-left">Скарги та
                        симптоми,
                        які ви вважаєте за необхідне вказати
                    </div>
                    {errors?.anamnesis?.type === "required" && (
                        <p className="form-review-error text-left"> Напишiть коментар </p>
                    )}
                    {errors?.anamnesis?.type === "maxLength" && (
                        <p className="form-review-error">
                            Занадто багато символів. Обмеження в 1000 символiв
                        </p>
                    )}
                    {errors?.anamnesis?.type === "pattern" && (
                        <p className="form-review-error text-left">Не валiднi данi. </p>
                    )}
                    <textarea
                        type="text"
                        {...register("anamnesis", {
                            required: true,
                            maxLength: 1000,
                            pattern: /^[a-zA-Zа-яА-Я\dёЁіІїЇєЄ' ,.!@?-]+$/u,
                        })}
                        placeholder="Введіть свій коментар (до 1000 символів)"
                        className="h-48 px-4 py-3 rounded border border-[#b8b5b5] text-white text-base font-normal leading-normal text-left bg-transparent resize-y placeholder:text-[#595353]"/>
                </div>
                {errors?.terms && (
                    <p className="form-review-error text-left">{errors.terms.message}</p>
                )}
                <label
                    className="w-full mt-6 mb-8 flex items-start gap-2 cursor-pointer select-none max-md:mb-4 max-md:gap-4">

                    <input
                        type="checkbox"
                        {...register("terms", {
                            required: "Ви повинні погодитися з нашою Політикою конфіденційності",
                        })}
                        className="hidden-checkbox"/>
                    <div className="checkbox-box"></div>
                    <div className="text-white text-xs leading-[150%] text-left">
                        Надсилаючи цю форму, ви погоджуєтеся з нашою &nbsp;&nbsp;
                        <Link to="/privacy-policy" className="text-[#418f44] underline hover:no-underline">
                            Політикою
                            конфіденційності.
                        </Link>
                    </div>
                </label>
                <button className="button">
                    Отримати інтерпретацію
                </button>
            </form>
        </>
    )
}