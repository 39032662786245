import Contactus from "../MainPage/components/contactus/Contactus";
import {useEffect} from "react";

export default function PrivacyPolicyPage() {

  useEffect(() => {
    const hash = window.location.hash.substring(1); // Remove the `#`
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <>
      <section className="pt-28 max-md:pt-14" id="privacy">
        <div className="inside">
          <div className="text-left text-lg tracking-wide text-[#00120a]  max-md:text-base">
            <div

                className="mb-20 text-center text-black text-[56px] font-bold leading-[120%] max-md:text-[32px] max-md:mb-10">
              Політика <br /> конфіденційності
            </div>
            <div className="mb-10text-2xl font-bold max-md:mb-6">
              Збір інформації
            </div>
            <div className="mb-14 font-normal max-md:text-base max-md:mb-8">
              Ця політика конфіденційності пояснює, як ми збираємо,
              використовуємо та захищаємо інформацію про користувачів, котрі
              використовують наш сервіс для аналізу медичних даних та отримання
              інтерпретації результатів медичних лабораторних досліджень. Ми
              зобов'язуємося забезпечити захист ваших персональних даних і
              дотримання чинного законодавства України в галузі охорони здоров'я
              та захисту інформації.
            </div>
            <div className="mb-10 text-2xl font-bold max-md:mb-6">
              Використання інформації
            </div>
            <div className="mb-10 font-normal max-md:text-base max-md:mb-6">
              Ми зобов'язуємося забезпечити захист ваших персональних даних і
              дотримання чинного законодавства України в галузі охорони здоров'я
              та захисту інформації. Зібрані дані використовуються для:
            </div>
            <div className="mb-3 pl-[54px] justify-start items-center gap-5 flex max-md:pl-0">
              <div className="w-2.5 h-2.5 bg-[#c3522f] rounded-sm" />
              <div className="grow shrink basis-0   font-normal ">
                Надання інтерпретації результатів лабораторних досліджень на
                основі аналізів пацієнта.
              </div>
            </div>
            <div className="mb-3 pl-[54px] justify-start items-center gap-5 flex max-md:pl-0">
              <div className="w-2.5 h-2.5 bg-[#c3522f] rounded-sm" />
              <div className="grow shrink basis-0   font-normal ">
                Забезпечення індивідуального підходу та точних результатів.
              </div>
            </div>
            <div className="mb-3 pl-[54px] justify-start items-center gap-5 flex max-md:pl-0">
              <div className="w-2.5 h-2.5 bg-[#c3522f] rounded-sm" />
              <div className="grow shrink basis-0   font-normal ">
                Поліпшення роботи сервісу і вдосконалення наших технологій.
              </div>
            </div>
            <div className="mt-14 mb-10 text-2xl font-bold max-md:mt-8 max-md:mb-6">
              Передача даних третім особам
            </div>
            <div className="mb-10 max-md:text-base max-md:mb-6">
              Ми не передаємо ваші персональні дані третім особам без вашої
              згоди, за винятком випадків, коли це вимагається чинним
              законодавством (наприклад, на запит суду або правоохоронних
              органів). Усі партнери, що можуть брати участь у наданні послуг
              (наприклад, лабораторії), зобов'язані дотримуватися стандартів
              захисту даних.
            </div>
            <div className="mb-5 text-[#418f44] font-bold">
              Захист інформації
            </div>
            <div className="mb-10 max-md:mb-6">
              Ми вживаємо всіх необхідних технічних і організаційних заходів для
              захисту персональної інформації пацієнтів від несанкціонованого
              доступу, втрати або викрадення. Це включає шифрування даних,
              захищені сервери та контроль доступу.
            </div>
            <div className="mb-5 text-[#418f44] font-bold">
              Термін зберігання інформації
            </div>
            <div className="mb-14 max-md:mb-8">
              Персональні дані користувачів сервісу зберігаються стільки,
              скільки необхідно для досягнення цілей, визначених цією політикою,
              або відповідно до вимог законодавства. Після завершення
              використання інформації ми знищуємо її відповідно до чинних
              стандартів безпеки.
            </div>
            <div className="mb-10 text-2xl font-bold max-md:mb-6">
              Зміни до політики конфіденційності
            </div>
            <div className="mb-14 max-md:mb-8">
              Ми залишаємо за собою право змінювати цю політику
              конфіденційності. Усі зміни будуть опубліковані на нашому
              вебсайті, і користувачі будуть повідомлені про важливі оновлення.
              Ми рекомендуємо регулярно переглядати політику для ознайомлення з
              оновленнями.
            </div>
          </div>
        </div>
      </section>
      <section className="pb-12">
        <div className="inside">
          <Contactus />
        </div>
      </section>
    </>
  );
}
