import React from 'react';
import {useModal} from "../../../../../../../providers/ModalProvider";


function MakeAnAppointmentLink() {
    const { open } = useModal();

    const handleClick = (e) => {
        e.preventDefault();
        open();
    };

    return (
        <button className="button" onClick={handleClick}>
            Записатись до лікаря
        </button>
    );
}

export default MakeAnAppointmentLink;