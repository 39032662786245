import {Document, Font, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import fontNormal from "../../../containers/app/fonts/Mulish-Medium.ttf";
import fontBold from "../../../containers/app/fonts/Mulish-Bold.ttf";
import React, {useEffect, useState} from "react";

Font.register({
    family: 'Mulish',
    fonts: [
        {src: fontNormal},
        {src: fontBold, fontWeight: 700}
    ],
});

const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontFamily: 'Mulish',
        fontSize: 12,
    },
    header: {
        fontSize: 16,
        fontWeight: 700,
        textAlign: 'center',
        backgroundColor: '#418F440D',
        position: 'relative',
        padding: '32px 24px',
        marginBottom: 12
    },
    text: {
        width: '75%',
        fontWeight: 500,
        textAlign: 'left',
        lineHeight: '1.5px'
    },
    logo: {
        width: 80,
        position: 'absolute',
        right: '15px',
        top: '45px'
    },
    backBigImg: {
        height: '60px',
        position: 'absolute',
        right: '80px',
        bottom: '0px',
        transform: 'rotate(0deg)'
    },
    backFooterBigImg: {
        height: '80px',
        position: 'absolute',
        right: '20px',
        bottom: '15px',
        transform: 'rotate(0deg)'
    },
    backSmallImg: {
        height: '30px',
        position: 'absolute',
        right: '50%',
        top: '0px',
        transform: 'rotate(0deg)'
    },
    sectionTitle: {
        marginTop: 10,
        fontSize: 14,
        fontWeight: 900,
        color: '#333',
    },
    infoSection: {
        marginTop: 10,
        padding: '0px 24px',
        color: 'black',
        flexDirection: 'row',
    },
    column: {
        flexGrow: 1,
        marginBottom: 6,
        padding: '0px 0px'
    },
    labelHead: {
        fontWeight: 700,
        fontSize: 10,
        marginBottom: 6,
    },
    labelBody: {
        fontWeight: 100,
        fontSize: 10,
        marginBottom: 6
    },
    resultSection: {
        paddingBottom: 10,
        marginBottom: 10,
        padding: '0px 24px',
    },
    boxNormal: {
        borderTop: '1px solid green',
        borderLeft: '1px solid green',
        borderTopLeftRadius: '4px',
        marginBottom: 22
    },
    boxNonNormal: {
        borderTop: '1px solid red',
        borderLeft: '1px solid red',
        borderTopLeftRadius: '4px',
        marginBottom: 22
    },
    resultNonNormal: {
        marginBottom: 12,
        borderRadius: 5,
    },
    resultNormal: {
        marginBottom: 12,
        borderRadius: 5,
    },
    twoNormalColumn: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#418F4408',
        paddingBottom: 10,
        marginBottom: 10,
        padding: '15px 20px',
    },
    twoNonNormalColumn:{
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#C3522F08',
        paddingBottom: 10,
        marginBottom: 10,
        padding: '15px 20px',
    },
    labelLeft: {
        flexBasis: '85%',
        fontWeight: 700,
        flexShrink: 0,
        flexGrow: 0,
    },
    labelNormalRight: {
        flexBasis: '15%',
        fontWeight: 700,
        textAlign: 'right',
        color: '#418F44',
        flexShrink: 0,
        flexGrow: 0,
    },
    labelNonNormalRight: {
        flexBasis: '15%',
        fontWeight: 700,
        textAlign: 'right',
        color: '#C3522F',
        flexShrink: 0,
        flexGrow: 0,
    },
    referenceText: {
        marginTop: 5,
        fontSize: 10,
        color: 'black',
        fontWeight: 700,
        marginBottom: 10,
        padding: '0px 20px',
    },
    simpleText: {
        color: '#00130A',
        lineHeight: '1.4px',
        fontSize: 10,
        padding: '0px 20px',
    },
    simpleNormalText: {
        color: '#00130A',
        lineHeight: '1.4px',
        fontSize: 10,
        padding: '0px 20px',
        fontWeight: 400
    },
    simpleFooterNormalText: {
        color: '#00130A',
        lineHeight: '1.4px',
        fontSize: 10,
        padding: '0px 20px',
        fontWeight: 400,
        width: '85%'
    },
    warning: {
        margin: 20,
        marginTop: 30,
        border: '1px dashed #418F44',
        gap: '12px',
        paddingBottom: 20,
        color: '#00130A'
    }
});

export default function PdfReport(props) {

    const [data, setData] = useState(null);

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem("clientInterpretation"));
        if (storedData) {
            let data = null;
            if (storedData?.results != null) {
                data = storedData?.results;
            } else if(storedData?.data != null) {
                data = storedData.data;
            }
            //let {data} = storedData;
            console.log(data)
            setData(data);
        }
    }, []);

    if (!data || !data.results) {
        console.error("PdfReport: Data is not available");
        return (
            <Document>
                <Page style={styles.page}>
                    <Text>Data is not available</Text>
                </Page>
            </Document>
        );
    }
    return (
        <Document>
            <Page style={styles.page}>
                <header style={styles.header}>
                    <Text style={styles.text}>Загальний висновок, зроблений штучним інтелектом за результатами наданих
                        досліджень</Text>
                    <Image style={styles.logo}
                           src="/img/logo.png"/>
                    <Image style={styles.backBigImg}
                           src="/img/header-pdf-big.png"/>
                    <Image style={styles.backSmallImg}
                           src="/img/header-pdf-small.png"/>
                </header>

                {/*<View style={styles.infoSection}>*/}
                {/*    <Text style={styles.sectionTitle}>Дані пацієнта</Text>*/}
                {/*</View>*/}
                {/*<View style={styles.infoSection}>*/}
                {/*    <View style={styles.column}>*/}
                {/*        <Text style={styles.labelHead}><Text style={styles.labelBody}>Ім'я:</Text> Поскреко Борис*/}
                {/*            Владиславович</Text>*/}
                {/*        <Text style={styles.labelHead}><Text style={styles.labelBody}>Дата народження:</Text> 28 квітня 1989*/}
                {/*            року</Text>*/}
                {/*    </View>*/}
                {/*    <View style={styles.column}>*/}
                {/*        <Text style={styles.labelHead}><Text style={styles.labelBody}>Стать:</Text> Чоловiча</Text>*/}
                {/*        <Text style={styles.labelHead}><Text style={styles.labelBody}>Дата:</Text> 07.10.2024</Text>*/}
                {/*    </View>*/}
                {/*    <View style={styles.column}>*/}
                {/*        <Text style={styles.labelHead}><Text style={styles.labelBody}>Код пацієнта:</Text> 0002375</Text>*/}
                {/*        <Text style={styles.labelHead}><Text style={styles.labelBody}>Номер пацієнта:</Text> 1708294</Text>*/}
                {/*    </View>*/}
                {/*</View>*/}

                <View style={styles.resultSection}>
                    <Text style={styles.sectionTitle}>Результати обстеження</Text>
                </View>

                {data?.results.map((oneResult, index) => {
                    if (!oneResult.is_abnormal) {
                        return (
                            <View key={index} style={styles.boxNormal}>
                                <View style={styles.resultNormal}>
                                    <View style={styles.twoNormalColumn}>
                                        <View style={styles.labelLeft}>
                                            <Text>{oneResult.test_name}</Text>
                                        </View>
                                        <View style={styles.labelNormalRight}>
                                            <Text>{oneResult.result}</Text>
                                        </View>
                                    </View>
                                    <Text style={styles.referenceText}>Референтні значення: &lt; 0.5 мг/л ФЕО</Text>
                                    <Text style={styles.simpleText}>
                                        {oneResult.interpretation}
                                    </Text>
                                </View>
                            </View>
                        );
                    } else {
                        return (
                            <View key={index} style={styles.boxNonNormal}>
                                <View style={styles.resultNonNormal}>
                                    <View style={styles.twoNonNormalColumn}>
                                        <View style={styles.labelLeft}>
                                            <Text>{oneResult.test_name}</Text>
                                        </View>
                                        <View style={styles.labelNonNormalRight}>
                                            <Text>{oneResult.result}</Text>
                                        </View>
                                    </View>
                                    <Text style={styles.referenceText}>Референтні значення: &lt; 0.5 мг/л ФЕО</Text>
                                    <Text style={styles.simpleText}>
                                        {oneResult.interpretation}
                                    </Text>
                                </View>
                            </View>
                        );
                    }
                })}

                <View style={styles.resultSection}>
                    <Text style={styles.sectionTitle}>Загальний висновок</Text>
                </View>
                <View>
                    <Text style={styles.simpleNormalText}>
                        {data?.general_recommendations}
                    </Text>
                </View>

                <View style={styles.warning}>
                    <View style={styles.resultSection}>
                        <Text style={styles.sectionTitle}>Важливе повідомлення</Text>
                    </View>
                    <Text style={styles.simpleFooterNormalText}>
                        Даний аналіз є результатом роботи штучного інтелекту, що не може бути використана для
                        самолікування
                        та
                        для детальної інтерпретації - зверніться до лікаря
                    </Text>
                    <Image style={styles.backFooterBigImg}
                           src="/img/footer-pdf-big.png"/>
                </View>

            </Page>
        </Document>
    );
}