import {configApi} from "./config";

export const getResultsFromPdf = async (clientData) => {
    const { baseIIUrl } = configApi;
    let url = `${baseIIUrl}/result-from-pdf`;
    try {
        const response = await fetch(url, {
            method: "POST",
            body: clientData,
            headers: {
                'Accept':'application/json'
            },
        });
        const data = await response.json();
        console.log(data);
        if (!data.success) {
            console.log("NOT SUCCESS");
        }
        return data;
    } catch (error) {
        return error;
    }
};
