import React from "react";
import ContactUsForm from "./components/ContactUsForm";
export default function Contactus() {

    return (
        <div id="callback-section"
            className="form-shadow mx-auto mt-14 p-16 grid grid-cols-2 gap-16 rounded-2xl bg-[url('/public/img/form2-bg.png')] bg-no-repeat bg-center bg-cover text-left max-xl:mt-10 max-xl:px-14 max-xl:py-16 max-xl:gap-24  max-md:mx-[-16px] max-md:w-[calc(100%+32px)] max-md:rounded-none max-md:grid-cols-1 max-md:py-14 max-md:px-4 max-md:gap-8">
            <div className="flex flex-col gap-12 text-white max-xl:gap-8">
                <div
                    className="text-[40px] font-bold leading-[130%] max-xl:text-[32px] max-md:text-2xl max-md:text-center">Стаємо
                    краще разом
                </div>
                <div className="text-lg leading-[150%] max-xl:text-base max-md:text-center">
                    Якщо ви також зацікавлені у розвитку ШІ або маєте інші пропозиції, напишіть нам, будь
                    ласка на
                    пошту <a href="mailto:ai@dniprolab.com"
                             className="font-bold underline hover:no-underline">ai@dniprolab.com</a> або
                    заповніть форму
                </div>
            </div>
            <div className="flex flex-col gap-4 items-start max-md:items-center">
                <ContactUsForm />
            </div>
        </div>
    )
}