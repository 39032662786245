import React, {useState} from "react";
import {Modal} from "@mantine/core";
import {useModal} from "../../../../../../../../providers/ModalProvider";
import './MakeAnAppointmentModal.scss'
import MakeAnAppointmentForm from "./components/MakeAnAppointmentForm";
import MakeAnAppointmentSuccsess from "./components/MakeAnAppointmentSuccsess";

const MakeAnAppointmentModal = ({healer}) => {
    const {opened, close} = useModal();
    const [isAppointmentFormed, SetAppointmentFormed] = useState(false);
    return (
        <Modal.Root opened={opened} onClose={close} size={isAppointmentFormed ? "45rem" : "65rem"}>
            <Modal.Overlay/>
            <Modal.Content>
                <Modal.Body className="my-modal-body">
                    <Modal.CloseButton className="modal-close-button"/>
                    {isAppointmentFormed ? (<MakeAnAppointmentSuccsess SetAppointmentFormed={SetAppointmentFormed}/>) : (
                        <MakeAnAppointmentForm healer={healer} SetAppointmentFormed={SetAppointmentFormed}/>
                    )}
                </Modal.Body>
            </Modal.Content>
        </Modal.Root>
    );
};

export default MakeAnAppointmentModal;


