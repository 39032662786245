import React from "react";
import NonClientTabForm from "./components/NonClientTabForm";


export default function NonClientTab(props) {
    const {clientInterpretation, SetClientInterpretation} = props;
    return (
        <>
            <NonClientTabForm SetClientInterpretation={SetClientInterpretation} clientInterpretation={clientInterpretation}/>
        </>
    )
}