import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './containers/app/App';
import {BrowserRouter} from "react-router-dom";
import {ModalProvider} from "./providers/ModalProvider";
import {MantineProvider} from "@mantine/core";
import {ParallaxProvider} from 'react-scroll-parallax';
import '@mantine/core/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ParallaxProvider>
            <MantineProvider withGlobalStyles withNormalizeCSS>
                <ModalProvider>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </ModalProvider>
            </MantineProvider>
        </ParallaxProvider>
    </React.StrictMode>
);
