import React, {useEffect, useState, useRef} from "react";
import HeaderSection from "./components/headerSection/HeaderSection";
import OurAchievements from "./components/OurAchievements";
import ClientReviews from "./components/ClientReviews";
import Reinterpretation from "./components/Reinterpretation/Reinterpretation";
import Interpretation from "./components/interpretation/Interpretation";
import {useLocation} from "react-router-dom";
import InterpretationResult from "./components/InterpretationResult/InterpretationResult";
import RecommendedHealers from "./components/recommendedHealers/RecommendedHealers";

export default function MainPage() {
    const location = useLocation();
    const [clientInterpretation, SetClientInterpretation] = useState(null);
    const interpretationResultRef = useRef(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.size === 3) {
            const targetSection = document.getElementById('interpretation-section');
            targetSection.scrollIntoView({behavior: "smooth"});
        }
    }, [location]);


    useEffect(() => {
        if (location.hash) {
            const targetSection = document.getElementById(location.hash.slice(1));
            if (targetSection) {
                targetSection.scrollIntoView({behavior: "smooth"});
            }
        }
    }, [location]);

    const shouldRenderInterpretationResult = () => {
        return clientInterpretation ? true : null;
    };

    const shouldRenderRecommendedHealers = () => {
        return (
            clientInterpretation &&
            clientInterpretation.showing_add //&&
            //clientInterpretation.add_to_visit_clinic.length > 0
        );
    };

    useEffect(() => {
        if (shouldRenderInterpretationResult() && interpretationResultRef.current) {
            interpretationResultRef.current.scrollIntoView({behavior: "smooth"});
        }
    }, [clientInterpretation]);

    return (
        <>
            <HeaderSection/>

            <OurAchievements/>

            <Interpretation
                SetClientInterpretation={SetClientInterpretation}
                clientInterpretation={clientInterpretation}
            />

            {shouldRenderInterpretationResult() && (
                <InterpretationResult
                    ref={interpretationResultRef}
                    clientInterpretation={clientInterpretation}
                />
            )}

            {shouldRenderRecommendedHealers() && (
                <RecommendedHealers clientInterpretation={clientInterpretation}/>
            )}

            <ClientReviews/>

            <Reinterpretation SetClientInterpretation={SetClientInterpretation}/>
        </>
    );
}
