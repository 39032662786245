import React, {useState} from "react";
import AnchorLink from "../../components/AnchorLink";
import {Link} from "react-router-dom";

export default function Header() {
    const [isHidden, setIsHidden] = useState(true);

    const handleClick = () => {
        setIsHidden(!isHidden);
    };

    return (
        <>
            <div className="h-24 max-md:h-[67px]"></div>
            <div
                className={`${
                    isHidden ? "hidden" : ""
                } fixed top-0 left-0 w-full h-[100vh] bg-[#021503]/60`}
            />
            <header className="fixed top-0 left-0 w-full z-50 bg-white">
                <div
                    className="mx-auto max-w-[1832px] px-5 py-6 flex justify-between items-center max-xl:px-12 max-md:px-4 max-md:py-5">
                    <Link to="/">
                        <img
                            src="/img/logo.svg"
                            alt=""
                            width="200px"
                            className="max-w-full max-xl:w-28 max-md:w-[200px]"
                        />
                    </Link>
                    <div className="flex items-center gap-8 max-md:hidden">
                        <AnchorLink href="/#howtoworkservice-section">
                            <a
                                href=""
                                className="text-base hover:text-[#418f44] transition duration-300 ease-in"
                            >
                                Як працює сервіс
                            </a>
                        </AnchorLink>
                        <AnchorLink href="/#callback-section">
                            <a
                                href="#"
                                className="text-base hover:text-[#418f44] transition duration-300 ease-in"
                            >
                                Зворотній зв’язок
                            </a>
                        </AnchorLink>
                        <AnchorLink href="/#interpretation-section">
                            <div className="button pulsate">Отримати інтерпретацію</div>
                        </AnchorLink>
                    </div>
                    <div className="hidden max-md:block" onClick={handleClick}>
                        {isHidden ? (
                            <svg
                                width="20"
                                height="15"
                                viewBox="0 0 20 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 1.50098H19M1 7.50098H19M1 13.501H19"
                                    stroke="#418F44"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        ) : (
                            <svg width="18" height="19" viewBox="0 0 18 19" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M17 17.5L1 1.5M17 1.5L1 17.5" stroke="#418F44" strokeWidth="2"
                                      strokeLinecap="round"/>
                            </svg>
                        )}

                    </div>
                </div>
                <div
                    className={`${
                        isHidden ? "hidden" : "flex"
                    } pt-6 pb-10 top-[88px] fixed left-0 w-full bg-white flex-col items-center`}
                >
                    <AnchorLink href="/#howtoworkservice-section">
                        <a
                            href=""
                            className="mb-4 inline-block text-black text-base font-normal leading-normal"
                            onClick={handleClick}
                        >
                            Як працює сервіс
                        </a>
                    </AnchorLink>
                    <AnchorLink href="/#callback-section">
                        <a
                            href="#"
                            className="mb-8 inline-block text-black text-base font-normal leading-normal"
                            onClick={handleClick}
                        >
                            Зворотній зв’язок
                        </a>
                    </AnchorLink>
                    <AnchorLink href="#interpretation-section">
                        <div className="button pulsate" onClick={handleClick}>
                            Отримати інтерпретацію
                        </div>
                    </AnchorLink>
                </div>
            </header>
        </>
    );
}
